import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { login } from '@/api/api'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
//https://jiaxiao.linkxspace.cn/?code=031qKYZv3lOoUU2TrH1w3rphKc3qKYZe&state=STATE

router.beforeEach((to, from, next) => {
  const redirect_uri = encodeURIComponent(window.location.href)
  const appid = 'wx43079171e0a73679' //  redirect_uri，授权登陆后的回调地址，需要进行encodeURIComponent处理
  const openid = sessionStorage.getItem('openid') // noLoginArr，白名单，不需要授权登陆的页面
  console.log(openid, 'openid')
  function splitUrl(url = window.location.href) {
    url = window.location.href
    if (url.indexOf('?') > -1) {
      let query = url.split('?')[1]
      const obj = {}
      if (query.indexOf('&') > -1) {
        query = query.split('&')
        query.map(item => {
          if (item.indexOf('=') > -1) {
            const [key, value] = item.split('=')
            obj[key] = value
          }
        })
      } else {
        if (query.indexOf('=') > -1) {
          const [key, value] = query.split('=')
          obj[key] = value
        }
      }
      return Object.keys(obj).length > 0 ? obj : null
    }
  }
  const urlObj = splitUrl()
  const code = urlObj && urlObj.code
  const state = urlObj && urlObj.state
  console.log(code, state)
  if (openid && openid !== 'undefined' && openid !== null) {
    // 白名单内不做登录判断，直接next
    next()
  } else {
    if (code) {
      // 登陆之后获取到code，传到后台登录
      const params = {
        code: code
      }
      console.log(params, 'params')
      login(params).then(res => {
        console.log(res, 'login')
        if (res.data.openid !== '') {
          sessionStorage.setItem('openid', res.data.openid)
          next()
        } else {
          console.log('请求失败')
          window.location.href =
          'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
          appid +
          '&redirect_uri=' +
          redirect_uri +
          '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'
        }
      })
    } else {
      // next()
      window.location.href =
        'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
        appid +
        '&redirect_uri=' +
        redirect_uri +
        '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'
    }
  }
})
// window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx43079171e0a73679&redirect_uri=https%3A%2F%2Fjiaxiao.linkxspace.cn%2Findex.html&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
export default router
