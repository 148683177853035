<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <div class="img">
      <img class="img-item" src="../assets/WechatIMG187.png" alt="">
      <div class="title">
        教练员服务质量满意度调查
      </div>
    </div>
    <div class="description">
      您好，我们是昆山交运驾培，昆山唯一的一家江苏省AAA级驾校。我们正在进行一项关于驾校教练员服务的满意度调查，诚邀您填答这份问卷，有助我们提高服务质量和教学水平，感谢您的大力支持！
    </div>
    <van-cell-group class="input-group">
      <!-- <van-field
        readonly
        clickable
        label="教练员姓名"
        :value="name"
        placeholder="请选择教练员"
        @click="showPickerFunc()"
      /> -->
      <van-field
          v-model="name"
          clearable
          label="教练员姓名"
          placeholder="请输入教练员"
          @input="changeEvent"
          @focus="showPicker=true"
        />
      <div class="search" v-show="showPicker&&name.length>0">
        <div class="name" v-for="(item, index) in columns1" :key="index" :name="item" @click.stop="choseName(item)">
          {{item.name}} - {{item.location}}
        </div>
        <div class="des" v-if="columns1.length===0">
          暂无数据
        </div>
      </div>
      <van-field
        readonly
        clickable
        label="驾校校区"
        :value="location"
      />
      <!-- <van-popup v-model="showPicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="columns1"
          @cancel="showPicker = false"
          @confirm="onConfirm"
        />
      </van-popup> -->
      <van-field name="radio" label="报名渠道">
        <template #input>
          <van-radio-group v-model="fromBy">
            <van-radio style="margin-top:5px;" v-for="(item, index) in columns" :key="index" :name="item.title">{{item.title}}</van-radio>
          </van-radio-group>
        </template>
      </van-field>
    </van-cell-group>

    <div class="formbody">
      <div class="item" v-for="(item, index) in radioData" :key="index">
        <div class="item-title">
          {{index + 1}}. {{item.title}}
        </div>
        
         <div class="item-radio" >
          <van-radio-group v-model="item.result" direction="horizontal">
            <van-radio v-for="choose in item.question_chooses" :key="choose.title" :name="choose.title">{{choose.title}}</van-radio>
          </van-radio-group>
        </div>
        <!-- <div class="item-radio" v-if="item.type == '1'">
          <van-radio-group v-model="item.result" direction="horizontal">
            <van-radio name="经常">经常</van-radio>
            <van-radio name="偶尔">偶尔</van-radio>
            <van-radio name="没有">没有</van-radio>
          </van-radio-group>
        </div>
        <div class="item-radio" v-if="item.type == '2'">
          <van-radio-group v-model="item.result" direction="horizontal">
            <van-radio name="是">是</van-radio>
            <van-radio name="否">否</van-radio>
          </van-radio-group>
        </div> -->
      </div>
      <div class="item">
        <div class="item-title">
          {{radioData.length + 1}}. 建议
        </div>
        <van-field
          v-model="personMessage"
          rows="2"
          autosize
          type="textarea"
          maxlength="200"
          placeholder="请填写你对驾校的建议或者个人感受"
          show-word-limit
        />
      </div>
    </div>
    <div class="btn">
      <van-button type="primary" block color='#3871ad' @click="postInfo">提 交</van-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { addInfo, getTitleList, getDriver, getSchool,getColumns } from '@/api/api'
import { Toast } from 'vant'
export default {
  name: 'Home',
  data() {
    return {
      name: '',
      fromBy: '',
      location: '',
      personMessage:'',
      columns: ['官方网站', '官方微信', '朋友介绍', '到校了解', '地推宣传', '其他'],
      schools: [],
      titleList: [],
      listLength: '',
      radioData: [],
      showPicker: false,
      columns1: []
    }
  },
  mounted() {
    this._getTitleList()
    this._getColumns()
    // this._getSchool()
  },
  methods: {
    _getColumns(){
      getColumns().then(res=>{
        this.columns = res.rows
      })
    },
    changeEvent(val) {
      console.log(val)
      if (val !== '') {
        this._getDriver(val)
      } else {
        this.columns1 = []
      }
    },
    choseName(val) {
      console.log(val)
      this.name = val.name
      this.location = val.location
      this.showPicker = false
      // this.canScroll()
    },
    // onConfirm(name) {
    //   if (this.location === '') {
    //     console.log('object')
    //     return
    //   }
    //   this.name = name
    //   console.log(this.name)
    //   this.showPicker = false
    // },
    // locationChange(e) {
    //   console.log(e)
    //   this._getDriver()
    // },
    _getTitleList() {
      const params = {
        is_del: 0
      }
      getTitleList(params).then(res => {
        console.log(res.data)
        const arr = []
        res.data.map((item, index) => {
          arr.push({
            title: item.title,
            title_no: item.id,
            result: '',
            type: item.type,
            question_chooses:item.question_chooses
          })
        })
        this.radioData = arr
        this.listLength = res.data.length
      })
    },
    _getDriver(val) {
      const params = {
        name: val
      }
      getDriver(params).then(res => {
        console.log(res)
        if (res.status === 200) {
          this.columns1 = res.data
        }
      })
    },
    _getSchool() {
      getSchool().then(res => {
        console.log(res, 'school')
        if (res.status === 200) {
          this.schools = []
          res.data.map(item => {
            this.schools.push(item.location)
          })
        }
      })
    },
    postInfo: function() {
      console.log(this.radioData)
      // 校验必填项
      const isExit = this.radioData.some((item, index) => {
        console.log(item, index)
        return item.result === ''
      })
      console.log(isExit)
      if (this.name === '') {
        Toast.fail('请填写教练名称')
        return false
      }
      if (this.fromBy === '') {
        Toast.fail('请选择报名渠道')
        return false
      }
      if (isExit) {
        Toast.fail('您还有问题未回答')
        return false
      }
      const data = {
        name: this.name,
        from_by: this.fromBy,
        location: this.location,
        result: this.radioData,
        openid: sessionStorage.getItem('openid'),
        personMessage:this.personMessage
      }
      console.log(data)
      addInfo(data).then(res => {
        console.log(res)
        if (res.status === 403) {
          Toast.success(res)
          this.radioData.map(item => {
            item.result = ''
          })
          this.fromBy = ''
          this.name = ''
          this.location = ''
          this.personMessage = ''
        } else {
          Toast.success('提交成功')
          this.radioData.map(item => {
            item.result = ''
          })
          this.fromBy = ''
          this.name = ''
          this.personMessage = ''
          this.location = ''
          setTimeout(() => {
            this.$router.push('/about')
          }, 500)
        }
      })
    },
    handleRadio(e) {
      console.log(1)
      console.log(e)
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  position: relative;
  .img {
    display: flex;
    margin: 40px auto 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .img-item {
      width:160px;
      height: 40px;
    }
    .title {
      text-align: center;
      font-size: 21px;
      color: #3871ad;
      margin-top: 15px;
    }
  }

  .description {
    margin: 20px;
    text-align: justify;
  }
  .input-group {
    position: relative;
    .search {
      position: absolute;
      z-index: 9999;
      top: 50px;
      left: 0;
      right: 0;
      height: 300px;
      background: rgb(236, 236, 236);
      overflow: scroll;
      .name {
        margin: 15px;
        padding-bottom: 10px;
        border-bottom: 1px solid #eee;
      }
    }
    .des {
      text-align: center;
      color: #d63c3c;
      margin-top: 20px;
    }
  }
  .info {
    margin-left: 20px;
    margin-bottom: 10px;
    color: rgb(76, 161, 231);
  }
  .formbody {
    margin: 10px 20px;
    .item {
      padding-bottom: 20px;
      border-bottom: 1px solid #eee;
      .item-title {
        font-size: 18px;
        font-weight: 500;
        margin-top: 30px;
      }
      .item-radio {
        margin-top: 10px;
      }
    }
  }
  .btn {
    margin: 20px;
    padding-bottom: 40px;
  }
}
</style>
