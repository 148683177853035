import request from '@/utils/http'

export function addInfo(data) {
  return request({
    url: '/driving/commit',
    method: 'post',
    data
  })
}

export function getTitleList(params) {
  return request({
    url: '/driving/titleList',
    method: 'get',
    params
  })
}
export function getColumns(){
  return request({
    url:'/role/findAllFrom',
    method:'post'
  })
}
export function login(params) {
  return request({
    url: '/driving/login',
    method: 'get',
    params
  })
}

export function getDriver(params) {
  return request({
    url: '/driving/driver',
    method: 'get',
    params
  })
}

export function getSchool(params) {
  return request({
    url: '/driving/schoolType',
    method: 'get',
    params
  })
}
