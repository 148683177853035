import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import Fastclick from 'fastclick'

Fastclick.attach(document.body)
Vue.use(Vant)
Vue.config.productionTip = false

Vue.prototype.noScroll = function() {
  const mo = function(e) { e.preventDefault() }
  document.body.style.overflow = 'hidden'
  document.addEventListener('touchmove', mo, false)
}

Vue.prototype.canScroll = function() {
  const mo = function(e) {
    e.preventDefault()
  }
  document.body.style.overflow = ''
  document.removeEventListener('touchmove', mo, false)
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
